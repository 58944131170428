import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import HubspotForm from "~/components/HubspotForm";

const ContactUs = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Design Trip"
				description="100% tailor-made based on your travel interests."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/upcoming-rides",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>
			<section className="mt-4 mb-10">
				<h1 className="font-bold">Design My Private Trip</h1>
				<p className="font-light mt-2 text-xl text-primary max-w-3xl mb-14">
					100% tailor-made based on your travel interests
				</p>
				{/* <p className="mb-10 prose">
					Ideal for family and friends, local clubs, and corporates, our team works
					closely with you and designs a trip from the ground up based on your dates,
					budget, destination, and travel interests. Pedal the Vietnamese coastline,
					kayak the backwaters of Kerala, or go hiking in Sri Lanka. There is no
					better way to travel the world!
				</p> */}
				{/*  You can also email us at{" "}
					<a
						
						className=" text-primary"
						href="mailto:contact@artofbicycletrips.com"
					>
						contact@artofbicycletrips.com
					</a>{" "}
					for any questions or booking a trip. */}

				{/* <h3 className="mb-10">Let's Create Your Dream Adventure</h3> */}
				<div className="max-w-2xl">
					<HubspotForm formID="9549b59b-0197-4161-89f5-dc72508a4c67" />
				</div>

				{/* <Helmet>
					<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>
				</Helmet>
				<iframe
					class="airtable-embed airtable-dynamic-height"
					src="https://airtable.com/embed/shrJK2IBkNl1dL95K?backgroundColor=green"
					frameborder="0"
					onmousewheel=""
					width="100%"
					height="927"
					//style="background: transparent; border: 1px solid #ccc;"
				></iframe> */}
				{/* <div className="relative max-w-3xl pb-form">
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLSeSxbFT3aDSvcKcctYxEtGLX_yKt9kxX6JHgifpUDXx2HuhzQ/viewform?embedded=true"
						width="100%"
						height="100%"
						frameborder="0"
						marginheight="0"
						marginwidth="0"
						className="absolute w-full h-full"
					>
						Loading…
					</iframe>
					<
				</div> */}
				{/* <div className="max-w-xs sm:max-w-lg md:max-w-xl lg:max-w-2xl">
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLSeSxbFT3aDSvcKcctYxEtGLX_yKt9kxX6JHgifpUDXx2HuhzQ/viewform?embedded=true"
						width="100%"
						height="1250"
						frameborder="0"
						marginheight="0"
						marginwidth="0"
						//className="absolute w-full h-full"
					>
						Loading…
					</iframe>
				</div> */}
				{/* <PopupButton
					id="SKjDwiLC"
					className="bg-primary py-2 px-2 sm:px-4 rounded-full text-white mr-4"
				>
					Fill Form
				</PopupButton> */}
			</section>
		</Layout>
	);
};

export default ContactUs;
