import React from "react";

class HubspotForm extends React.Component {
	componentDidMount(props) {
		const script = document.createElement("script");
		script.src = "https://js.hsforms.net/forms/shell.js";
		document.body.appendChild(script);

		script.addEventListener("load", () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					region: "na1",
					portalId: "21773256",
					formId: this.props.formID, //also you can do fromID: `${this.props.formID}`
					target: "#hubspotForm",
				});
			}
		});
	}

	render() {
		return (
			<div>
				<div id="hubspotForm"></div>
			</div>
		);
	}
}

export default HubspotForm;
